import React, { useCallback, useState } from 'react';

import { TertiaryButton } from '../../components/Buttons/TertiaryButton';
import Logo from '../../images/Header/Logo';
import CloseIcon from '../Icons/Close';
import Humburger from '../Icons/Humburger';
import SideNav from '../SideNav/SideNav';

import style from './Header.module.scss';

import cn from 'classnames';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export interface IHeaderProps {
  className?: string;
  isDarkTheme?: boolean;
}

const Header = ({ className, isDarkTheme = false }: IHeaderProps) => {
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  const showSidebarMenu = () => {
    if (!isSidebarVisible) {
      setSidebarVisibility(true);
    }
  };

  const handleSidenavClose = useCallback(() => {
    if (isSidebarVisible) {
      setSidebarVisibility(false);
    }
  }, [isSidebarVisible]);

  const handleButtonClick = () => {
    location.href = 'mailto:kontakt@fanind.pl';
  };

  return (
    <>
      <header className={cn(className, style.header, { [style.dark]: isDarkTheme })}>
        <div className="row align-items-center">
          <div className="col">
            <Link to="/" className={style.logo}>
              <Logo />
            </Link>
          </div>
          <div className={cn('col-auto', style.links)}>
            <Link activeClassName={style.active} to="/about/">
              About
            </Link>
            <Link activeClassName={style.active} to="/solutions/">
              Solutions
            </Link>
            <TertiaryButton
              className="d-none d-lg-inline-block"
              onClick={handleButtonClick}
              isLight={isDarkTheme}
            >
              Contact Us
            </TertiaryButton>
            <Humburger
              className={cn('d-lg-none', style.hamburger)}
              onClick={showSidebarMenu}
              aria-label="Open Side Nav"
            />
          </div>
        </div>
      </header>
      <SideNav isOpen={isSidebarVisible} onClose={handleSidenavClose}>
        <div className={style.buttonsSection}>
          <div className="row align-items-center">
            <div className="col">
              <Link to="/" className={style.logo}>
                <Logo />
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={style.closeButton}
                onClick={handleSidenavClose}
                aria-label="Close Side Nav"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </div>
        <div className={style.sideNavLinks}>
          <Link activeClassName={style.active} to="/about/">
            About
          </Link>
          <Link activeClassName={style.active} to="/solutions/">
            Solutions
          </Link>
          <Link activeClassName={style.active} to="/privacy/">
            Privacy Policy
          </Link>
        </div>
      </SideNav>
    </>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  className: '',
  siteTitle: '',
};

export default Header;
