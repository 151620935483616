import React from 'react';

import { IIconProps } from '../../interfaces/IconInterface';

const LogoIcon = (props: IIconProps) => {
  const { width = 150, height = 56, className } = props;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 150 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 56c15.464 0 28-12.536 28-28S43.464 0 28 0 0 12.536 0 28s12.536 28 28 28z"
        fill="#000"
      />
      <path
        d="M41.781 21.766h-8.75l4.375-7.547H18.922l-4.813 8.312 13.672 23.625 5.25-9.078-3.61-6.234h7.22l5.14-9.078zm-6.89-6.016l-3.5 6.016h-7.22l-3.5-6.016h14.22zm-7.11 27.344L15.86 22.53l3.5-6.015 11.922 20.562-3.5 6.016zm.766-13.782l-3.5-6.015H39.266l-3.5 6.015h-7.22z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.125 20.508l-.438 3.172h-7.656v3.718h6.344l-.438 3.172h-5.906v4.922H64.75V20.508h11.375zm34.891 0h3.281v14.984h-3.281V20.508zm31.937 0h-6.672v14.984h6.672a6.115 6.115 0 0 0 6.125-6.125v-2.844c0-3.28-2.734-6.015-6.125-6.015zm2.844 8.969c0 1.53-1.203 2.843-2.844 2.843h-3.391v-8.422h3.391c1.531 0 2.844 1.204 2.844 2.844v2.735zm-49.875-8.97l7.984 9.407-.109-9.406h3.281v14.984h-2.406l-7.984-9.297.109 9.297h-3.281V20.508h2.406zm32.813 9.407l-7.985-9.406h-2.296v14.984h3.281l-.219-9.297 7.984 9.297h2.407V20.508h-3.282l.11 9.406zm-52.938 5.578l6.672-14.984h2.844l6.672 14.984h-3.828l-4.266-10.39-4.266 10.39h-3.828z"
        fill="#000"
      />
    </svg>
  );
};

export default React.memo(LogoIcon);
