import React from 'react';

import style from './Footer.module.scss';

import { Link } from 'gatsby';

const Footer = () => {
  return (
    <footer className={style.footer}>
      <div className="container">
        <Link activeClassName={style.active} to="/about/">
          About
        </Link>
        <Link activeClassName={style.active} to="/solutions/">
          Solutions
        </Link>
        <Link activeClassName={style.active} to="/privacy/">
          Privacy &amp; Policy
        </Link>
        <p>© 2015–{new Date().getFullYear()} Fanind. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
